@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {

  .kc-caption {
    @apply font-light leading-tight tracking-tight text-xl sm:text-3xl;
  }

  .kc-summary {
    @apply text-sm sm:text-base 2xl:text-lg text-gray-500;
  }

  .kc-textfield {
    @apply block w-full px-2 py-3 2xl:px-3 2xl:py-4 text-base bg-gray-50 border border-black text-gray-900 rounded-lg focus:ring-primary-normal focus:border-primary-normal;
  }

  .kc-textfield.invalid {
    @apply bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500;
  }

  .kc-textfield:disabled {
    @apply bg-gray-100 border border-gray-300 text-gray-500 focus:ring-primary-normal focus:border-primary-normal;
  }

  .kc-textfield-toggle {
    @apply absolute inset-y-0 right-0 px-3 flex items-center focus:outline-none text-gray-300 hover:text-gray-500;
  }

  .kc-checkbox {
    @apply w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-2 focus:ring-primary-normal;
  }

  .kc-label {
    @apply block mb-2 text-sm text-gray-600;
  }

  .kc-label.invalid {
    @apply text-red-700;
  }

  .kc-button-link {
    @apply px-3 py-3 2xl:px-4 2xl:py-4 2xl:text-xl 2xl:leading-8 font-medium text-primary-normal cursor-pointer hover:no-underline hover:text-primary-light;
  }

  .kc-button {
    @apply px-3 py-3 2xl:px-4 2xl:py-4 2xl:text-xl 2xl:leading-8 font-medium rounded-lg text-center text-primary-accent bg-primary-normal cursor-pointer hover:bg-primary-light focus:ring-4 focus:outline-none focus:ring-primary-normal focus:ring-opacity-25;
  }

  .kc-button:disabled {
    @apply bg-gray-200 text-gray-400 cursor-not-allowed;
  }

  .kc-button.light {
    @apply border text-gray-900 focus:outline-none bg-white border-gray-200 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:ring-0 focus:ring-gray-200
  }

}